//  comms
//
//  desc:
//    functions that can be called to handle communications with an api 
//    When adding a new function, function should accept a callback, all data that may be needed
//      Function should use the 'callToEndpoint' commsHelper function for making a server call
//      If the function needs an endpoint path that has not yet been defined in the below list, please add it
//      and make use of it in the function when calling 'callToEndpoint'

//  current functions list:
//    getMerchantInfo(merchant_id, callback)
//
//

import {callToEndpoint} from './commsHelper.js';        //callToEndpoint performs the actual network communications 
import forge from 'node-forge'


// endpoints for api calls should be defined here for use by calling functions
//    endpoint is a hash that contains the endpoint path and the endpoint type
//              path should be of the type "/index.html" 
//              type should be either "GET" or "POST"
//            example const ENDPOINT_GET_SOME_INFO = {path: '/info/path', type: "GET"}

const ENDPOINT_GET_MERCHANT_INFO = {path: '/v1/guest/mid_info', type: "GET"}
const ENDPOINT_GET_MERCHANT_SUR_INFO = {path: '/v1/guest/merchant_sur_info', type: "POST"}
const ENDPOINT_GET_SURCHARGE_AMOUNT_INFO = {path: '/v1/guest/surcharge_amount_info', type: "POST"}
const ENDPOINT_PROCESS_TRANSACTION = {path: "/v1/guest/process_transaction", type: "POST"}
const ENDPOINT_CREATE_RECURRING = {path: '/v1/guest/create_recurring', type: "POST"}
const ENDPOINT_CUSTOMER_LOGIN = {path: "/v1/guest/login", type: "POST"}
const ENDPOINT_CUSTOMER_LOGOUT = {path: "/v1/guest/logout", type: "POST"}
const ENDPOINT_CUSTOMER_LOGIN_STATUS = {path: "/v1/guest/login_status", type: "POST"}
const ENDPOINT_CREATE_CUSTOMER_ACCOUNT = {path: '/v1/guest/customer_create', type: "POST"}
const ENDPOINT_UPDATE_CUSTOMER_ACCOUNT =  {path: '/v1/guest/customer_update', type: "POST"}
const ENDPOINT_GET_PAYMENT_LINK_PARAMS = {path: '/v1/guest/get_payment_link', type: "GET"}
const ENDPOINT_GET_DISCRETIONARY_DATA = {path: '/v1/guest/discretionary_data', type: "GET"}
const ENDPOINT_FORGOT_CUSTOMER_LOGIN = {path: '/v1/guest/forgot_password', type: "POST"}

// getMerchantInfo
//  
// returns merchant info necessary for comus to be able to set up card page 
//
//  Params:
//    merchant_id:  id of the merchant that is to be looked up (generally, the merchant id was probably received as a url param to comus)
//    callback:     callback function to be called with the returned json data from the server api call
//  
// example response data: 
//   ach: true
//   dba: "Awesome Merchant"
//   email: "jason@awesomemerchant.com"
//   mid: "888000002887"
//   success: true
//   website: "https://awesomemerchant.com"
//   recur: true
export function getMerchantInfo(merchant_id, callback)
{
  var data = { merchant_id: merchant_id }
  return callToEndpoint(ENDPOINT_GET_MERCHANT_INFO, data)
}

export function getMerchantSurInfo(merchant_id, omit_surcharge)
{
  var data = { merchant_id: merchant_id, omit_surcharge: omit_surcharge }
  
  return callToEndpoint(ENDPOINT_GET_MERCHANT_SUR_INFO, data)
}

export function getSurchargeAmountInfo(merchant_id, amount, card_bin, customer_id, state)
{
  var data = { merchant_id: merchant_id, amount: amount, card_bin: card_bin, customer_id: customer_id, state: state}
  
  return callToEndpoint(ENDPOINT_GET_SURCHARGE_AMOUNT_INFO, JSON.stringify(data)).then(function(resp){
    return resp.data;
  })
}


export function submitRecurringPayment(data)
{
  // console.log("creating recurring", data);
  return callToEndpoint(ENDPOINT_CREATE_RECURRING, data);
}

export function getE2eePublicKey(merchantID)
{
  var endpoint =
  {
    type: 'GET',
    path: '/v1/guest/get_e2ee_public_key?merchant_id=' + merchantID
  };
  return callToEndpoint(endpoint);
}

export function encrypt (plaintext, e2eePublicKey)
{
  if (!e2eePublicKey) {
    throw new Error('Please call initE2ee() before using encryption!')
  }

  const key = forge.pki.publicKeyFromPem(e2eePublicKey)

  return forge.util.encode64(key.encrypt(plaintext, 'RSA-OAEP'))
}

export function submitPayment(merchantID, formdata)
{
  var endpoint =
  {
    type: 'POST',
    path: '/v1/guest/process_transaction?merchant_id=' + merchantID
  };

  return callToEndpoint(endpoint, formdata);
}

// NOTE: this is the only one that unwraps the json response data property
export function forgotCustomerLogin(username, merchantId)
{
  var data = { email_id: username, merchant_id: merchantId };
  return callToEndpoint(ENDPOINT_FORGOT_CUSTOMER_LOGIN, data).then(function(resp){
    return resp.data;
  });
}

export function customerLogin(username, password, merchantId, callback)
{
  var data = { username: username, password: password, merchant_id: merchantId };
  return callToEndpoint(ENDPOINT_CUSTOMER_LOGIN, data).then(function(resp){
    // unwrap data from response so caller doesn't need to do it.
    // NOTE not sure where it does this but somewhere in the system, suspecting the
    // legacy system the username comes back as the server field which will be the value 'Cart'.
    // On purpose for security? We really only care about token though so make sure not
    // to depend on the USERNAME for anything to identify the customer.
    return resp.data;
  });
}

export function customerLogout(merchantId, callback)
{
  callToEndpoint(ENDPOINT_CUSTOMER_LOGOUT, {merchant_id: merchantId}, callback)
}

export function customerLoginStatus(callback)
{
  callToEndpoint(ENDPOINT_CUSTOMER_LOGIN_STATUS, {}, callback)
}

export function NewCustomerAccount(jsonData)
{
  return callToEndpoint(ENDPOINT_CREATE_CUSTOMER_ACCOUNT, jsonData)
}

export function UpdateCustomerAccount(jsonData)
{
  return callToEndpoint(ENDPOINT_UPDATE_CUSTOMER_ACCOUNT, jsonData);
}

// GetPaymentLink
//  
// returns PaymentLink for merchant
//
//  Params:
//    merchant_id:  id of the merchant that is to be looked up (generally, the merchant id was probably received as a url param to comus)
//  
// example response data: 
//   LinkParams: base64 string encoded payment link params 
export function GetPaymentLink(jsonData)
{
  return callToEndpoint(ENDPOINT_GET_PAYMENT_LINK_PARAMS, jsonData);
}


export function GetDiscretionaryData(jsonData)
{
  return callToEndpoint(ENDPOINT_GET_DISCRETIONARY_DATA, jsonData);
}

